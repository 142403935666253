body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}
p{
font-family: 'Poppins', sans-serif;
}
.container {
  width: 92.2%;
  margin: 0px auto;
  max-width: 92.2%;
}
.desktop-show{
  display: block;
}
.mobile-show{
  display: none;
}
.header {
  width: 100%;
  float: left;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  border-bottom: solid 1px #b2b2b2;
}

.headerstr {
  width: 100%;
  float: left;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.navbar {
  width: auto;
  float: left;
  display: inline-block;
  padding: 0;
  justify-content: inherit;
  flex-wrap: inherit;
  align-items: inherit;
  position: static;
}

.navbar ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar ul li {
  padding: 0 20px;
  list-style: none;
}

.navbar ul li a {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #000;
  text-decoration: none;
  font-weight: 300;
}
.navbar ul li:last-child {
  padding-right: 0;
}
section {
  width: 100%;
  float: left;
}

.banner {
  width: 100%;
  float: left;
}

.banner img {
  width: 100%;
  height: 100vh;
}
.bannercont {
  position: absolute;
  top: 30%;
  right: 15%;
  width: 25%;
}

.bannercont h2 {color: #9f8468;font-size: 27px;line-height: 30px;font-family: 'Poppins', sans-serif;text-transform: uppercase;letter-spacing: 2px;margin: 10px 0; position: relative;}

.bannercont p {
  font-size: 25px;
  line-height: 32px;
  font-family: 'Playfair Display', serif;
  margin: 0;
  font-style: italic;
  letter-spacing: 1px;
}
.bannercont h2:before {left: -85px;content: '';width: 60px;position: absolute;height: 3px;background: #9f8468;top: 50%;transform: translateY(-50%);}
.icon01 {
  position: absolute;
  bottom: 0;
  right: 50px;
}

.banner .icon img {width: 50px;height: auto;}

.circle {
  width: 300px;
  height: 300px;
  border: solid 5px #9f8468;
  border-radius: 100%;
  background: transparent;
  display: inline-block;
}
.circle.right-align {
  position: absolute;
  bottom: 20%;
  right: -300%;
}
.icon02 {
  position: absolute;
  width: 300px;
  height: 300px;
  left: 0;
  top: -120px;
}
.icon05 {
  right: 0;
  left: auto;
}
.icon02 .circle {
  opacity: 0.5;
  width: 375px;
  height: 375px;
}
body {
  overflow-x: hidden;
}

@keyframes slow-move-anticlockwise {
  0% {
      -webkit-transform: rotate(0deg) translateX(-5px) rotate(0deg);
      transform: rotate(0deg) translateX(-5px) rotate(0deg);
  }

  50% {
      -webkit-transform: rotate(-360deg) translateX(-10px) rotate(357deg);
      transform: rotate(-360deg) translateX(-10px) rotate(357deg);
  }

  100% {
      -webkit-transform: rotate(-720deg) translateX(-5px) rotate(720deg);
      transform: rotate(-720deg) translateX(-5px) rotate(720deg);
  }
}

.slow-move-anticlockwise {
  -webkit-animation: slow-move-anticlockwise 8s cubic-bezier(0.41, 0.17, 0.57, 0.79) infinite both;
  animation: slow-move-anticlockwise 8s cubic-bezier(0.41, 0.17, 0.57, 0.79) infinite both;
}

.aboutimg {
  width: 46%;
  position: relative;
}
.aboutcont {
  width: 26%;
  margin-right: 10%;
}
.aboutus {
  display: flex;
  width: 100%;
  float: left;
  align-items: center;
  justify-content: space-between;
}

.aboutstn {
  width: 100%;
  float: left;
  padding: 100px 0;
  position: relative;
}
.title {
  font-size: 35px;
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.aboutimg img {
  width: 100%;
}

.aboutcont p, .accordion__panel p {
  font-size: 15px;
  line-height: 34px;
  letter-spacing: 0.6px;
  font-weight: 300;
}
.accordion__panel p {
  font-weight: 400;
}
.title:before {left: -100px;content: '';width: 70px;position: absolute;height: 3px;background: #9f8468;top: 50%;transform: translateY(-50%);}
.btn {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.btn span {
  width: 60px;
  height: 60px;
  float: left;
  border-radius: 100%;
  border: solid 2px #9f8468;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: ease all 1s;
}

.btn a {
  float: left;
  display: flex;
  align-items: center;
  font-size: 13.5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  background-image: linear-gradient(to right,#9f8468,#9f8468 50%,#000 50%);
  transform: translateY(0);
    transition: transform 2s 3s,background-position 2s;
    font-weight: 300;
}
.btn a:hover {
  background-position: 0;
}
.btn span:before {
  content: '';
  background-image: url(./images/larrow.png);
  background-size: contain;
  width: 20px;
  height: 20px;
}

.btn span:after {
  content: '';
  width: 10px;
  height: 20px;
  position: absolute;
  border: #fff solid 2px;
  background: #fff;
  right: -5px;
}
.border1 {
  width: 30px;
  height: 30px;
  border: solid 4px #9f8468;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  top: -25%;
  left: 20%;
}

.border2 {
  width: 20px;
  height: 20px;
  border: solid 3px #9f8468;
  display: inline-block;
  border-radius: 100%;
}

.aboutcont .border2 {
  position: absolute;
  right: -30%;
}
.icon03 {
  position: absolute;
  bottom: 0;
  right: 5%;
}
.border3 {
  width: 15px;
  height: 15px;
  border: solid 2px #9f8468;
  display: inline-block;
  border-radius: 100%;
}
.icon .border3 {
  position: absolute;
  right: 0;
}

.borderfil4 {
  width: 10px;
  height: 10px;
  border: solid 2px #9f8468;
  display: inline-block;
  border-radius: 100%;
  background: #9f8468;
}

.icon .borderfil4 {
  position: absolute;
  left: -65%;
  bottom: 30%;
}


.heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.heading[data-type='career'] {
  padding-top: 2em;
  margin-bottom: 2em;
}
.companiesstn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 50px 0;
  float: left;
  flex-wrap: wrap;
}

.companiesbox {
  width: 15%;
  margin: 0 0.5%;
  overflow: hidden;
}

.companiesbox span {
  width: 100%;
  text-align: center;
  float: left;
  margin-top: 15px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 24px;
}
.companies .btn {
  justify-content: center;
  margin-top: 25px;
}
.companies .border2 {
  position: absolute;
  right: 10%;
  bottom: 20%;
}

.companies .borderfil4 {
  position: absolute;
  bottom: 10%;
  right: 15%;
}
.companies .border3 {
  position: absolute;
  top: 15%;
  left: 10%;
}
.companies.sector {
  margin-top: 50px;
  background-image: url(./images/sector.jpg);
  padding-top: 100px;
}
.companies.sector .title, .companiesbox span {
  color: #fff;
}
footer {
  width: 100%;
  float: left;
  padding: 100px 0 0 0;
  position: relative;
}
.footer {
  background: #cfcdce;
  padding: 50px;
  position: relative;
}
footer .icon02 {
  top: 0;
  left: -10%;
}

footer .icon {
  position: absolute;
  top: 40%;
  left: -15%;
}
.footercontainer {
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}
.footerbox {
  width: 33%;
  float: left;
}

.footerbox p {
  font-size: 14px;
  line-height: 28px;
  font-weight: 300;
  padding-right: 50px;
  color: #000;
  letter-spacing: 0.5px;
}

.footerbox ul {
  width: 100%;
  float: left;
  list-style: none;
}

.footerbox ul li {
  font-size: 19px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
}

.footerbox ul li:after {content: '';width: 40px;height: 2px;background: #9f8468;position: absolute;left: 0;border: 0;}

.footerbox h3 {
  font-size: 32px;
  line-height: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.footerbox h4 {
  font-size: 22px;
  color: #9f8468;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.footerbox ul li:nth-child(1):after {
  opacity: 0;
  display: none;
}
.copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  float: left;
}

.copyright p {
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000;
  margin: 10px 0;
}
.logo {
  width: auto;
  float: left;
}
.navbar.active .header .logo img{
  width: 80%;
}
.navbar.active .header {
  position: fixed;
  top: 0;
  left: 0;
  animation: smoothScroll 1s forwards;
  background: #fff;
  padding: 10px 0;
  z-index: 1;
  box-shadow: 1px 1px 5px rgb(85 85 85 / 36%);
  right: 0;
}

@keyframes smoothScroll {
  0% {
      transform: translateY(-40px);
  }

  100% {
      transform: translateY(0px);
  }
}
.btn a:hover .btnarrow {
  border-color: #000;
}

.btn a:hover .btnarrow span:before {
  -webkit-text-fill-color: #9f8468;
}
.footerbox p a {
  color: #9f8468;
}
.webbanner, .footerbox p a{
  width: 100%;
  float: left;
}

.webbanner .title {
  position: absolute;
  top: 30%;
  left: 65%;
  transform: translate(-50%,-50%) !important;
  font-size: 27px;
  line-height: 32px;
}

.web-container {
  width: 80%;
  margin: 0px auto;
}

.content50 {
  width: 80%;
  float: left;
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 0 10%;
}

.content50 p {
  width: 45%;
  
}
.investcont p, .content50 p, .exiteuscont p, .heading p, .esgcont p, .accordion .accordion-item .panel p, .exiteuscont ul li{
  font-size: 15px;
  line-height: 36px;
  letter-spacing: 0.6px;
  font-weight: 300;
  color: rgb(0 0 0 / 90%);
}

.content50 p strong {
  width: 100%;
  float: left;
  margin-top: 25px;
}
.webbanner .icon.icon01 {
  bottom: 0;
}
.whoweare .icon02 {
  top: 20%;
  left: 0.5%;
}

.whoweare .border1 {
    top: 20%;
    left: 6%;
}
.bannerconts {
  background-image: url(./images/abtimg.jpg);
  background-attachment: fixed;
    background-size: cover;
    background-position: top;
    height: 50vh;
}
.bannerconts h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 53px;
  line-height: 64px;
  font-weight: normal;
  color: #ae8f6f;
  width: 60%;
  margin: 0;
  font-family: 'Playfair Display', serif;
}
.whoweare .border2 {
    position: absolute;
    right: 10%;
    bottom: 10%;
}
.bannerconst {
  float: left;
  width: 100%;
  position: relative;
}

.bannerconst .border3 {
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.bannerconst .borderfil4 {
  position: absolute;
  right: 30%;
  bottom: -10%;
}

.investment {flex-direction: row-reverse;margin: 50px 0;align-items: center;justify-content: space-between;}

.investcont {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin-left: 28%;
  margin-right: 0%;
}

.investimg {
    float: right;
    width: 40%;
}
.investcont p {
  margin-top: 50px;
}

.link {
  border: solid 1px #9f8468;
  width: auto;
  height: 50px;
  padding: 10px 50px;
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-top: 20px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.link a:hover {
  color: #fff;
}
.investment .icon01 {top: 0;bottom: inherit;left: 5%;display: inherit;}

.investment .icon02 {
    top: 25%;
    left: -5%;
}
.investment .border3 {
  position: absolute;
  left: 9%;
  top: 32%;
}

.companiesstn.black span {
    color: #000;
}
.companies.aboutbanner2 {
  background-image: url(./images/aboutbanner2.jpg);
  height: 50vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.companies.aboutbanner2 .icon01 {
  bottom: -40%;
  z-index: -1;
}
.exiteus {
  flex-wrap: wrap;
}

.exitestn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.exiteus .icon02 {
  top: -20%;
}

.exiteuscont {
  width: 30%;
  text-align: left;
  margin-right: 20%;
  position: relative;
}
.exiteus .border1 {
  position: absolute;
  left: 5%;
  top: 0%;
  z-index: 9;
}
.exiteuscont h3 {
  font-size: 26px;
  line-height: 32px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.exiteus .border2 {
  position: absolute;
  bottom: 5%;
  right: 5%;
}
.exiteusimg {
  position: relative;
}
.exiteus .exitestn:nth-child(odd) {flex-direction: row-reverse;}

.exiteus .exitestn:nth-child(odd) .exiteuscont {
    margin-left: 20%;
    margin-right: 0;
}
.exiteus .exitestn:nth-child(odd) .icon02 {
  top: auto;
  bottom: -10%;
  left: -5%;
}
.exiteus .exitestn:nth-child(odd) .icon02 {
  top: auto;
  bottom: -10%;
  left: -5%;
}

.exiteus .exitestn:nth-child(odd) .border2 {
  left: 10%;
  right: auto;
  bottom: 15%;
}
.exitestn .border1 {
  left: auto;
  right: -40%;
  top: 50%;
}
.exiteuscont .borderfil4 {
  left: auto;
  right: -50%;
  position: absolute;
  bottom: -50%;
}
.heading p {
  width: 90%;
  margin: 30px 10% 0;
  float: left;
}
.people {
  display: flex;
  width: 100%;
  float: left;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.peoplebox {
  width: 25%;
  float: left;
  margin: 3%;
  position: relative;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgb(0 0 0 / 18%);
}
.peopleimg {
  float: left;
  width: 100%;
  background-image: url(./images/probg.jpg);
  background-size: cover;
  height: 450px;
  overflow: hidden;
}
.peopleimg img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  filter: grayscale(1);
}
.peoplecont {
  float: left;
  width: 100%;
  height: auto;
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 18%); */
  padding: 30px 50px;
}

.peoplecont h4, .peoplecont span {
  float: left;
  width: 100%;
  text-align: left;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
*, ::after, ::before {
  box-sizing: border-box;
}
.peoplecont span {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #6d6e71;
}
.peoplecont h4 {
  font-size: 19px;
  letter-spacing: 0.6px;
  line-height: 30px;
}
.exitestn.investments {align-items: flex-start;}

.exitestn.investments .exiteuscont {
    width: 38%;
    margin-right: 15%;
    margin-top: 10%;
}

.exitestn.investments .exiteuscont h3:before {
  content: '';
  width: 40%;
  height: 4px;
  background: #cfc1b3;
  position: absolute;
  left: -45%;
  top: 12px;
}
.exiteus .exitestn.investments:nth-child(odd) .exiteuscont h3:before {content: '';right: 0;left: auto;}

.companies, .whoweare, .bannerconts, .investment, .exiteus, .esg, .contactdetils, .currentopening {
  padding: 50px 0;
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
}
.esfstn {
  display: flex;
  width: 100%;
  float: left;
  justify-content: space-between;
  position: relative;
  margin-top: 50px;
}

.esfbox {
  width: 30%;
}

.esgcont, .esfimg {
  width: 100%;
  float: left;
}

.esgcont h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
}
.notshow {
  display: none;
}
.esg .border1 {
    top: 18%;
    left: -5%;
}

.esg .border1.sec {
    left: auto;
    right: -10%;
    top: 25%;
}

.esg .border3 {
    top: 30%;
    position: absolute;
    right: -10%;
}

.esg .borderfil4 {
    position: absolute;
    left: -12%;
    bottom: 50%;
}

.esg .border2 {
    position: absolute;
    bottom: 0;
    right: -10%;
}
.careerlink a {
  text-decoration: none;
  color: #9f8468;
  font-weight: 300;
  letter-spacing: 0.5px;
  border-bottom: #9f8468 solid 1px;
}
.contactdetails {
  width: 80%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 0 10%;
}

.contact50 {
  width: 30%;
}

.contact50 img {
  width: 100%;
}

.contactdetails .contact50:nth-child(1) {
  width: 60%;
}

.contactdetails .contact50 h4 {
  font-size: 22px;
  color: #9f8468;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
}
.contactdetails .contact50 p {
  font-size: 14px;
  line-height: 28px;
  font-weight: 300;
  padding-right: 50px;
  color: #000;
  letter-spacing: 0.5px;
}

.contactdetails .contact50 p a {
  color: #9f8468;
}

.contactdetils .border1 {
    left: 5%;
    top: 10%;
}

.contactdetils .border2 {
    position: absolute;
    right: 10%;
    top: 80%;
}

.contactdetils .borderfil4 {
    position: absolute;
    top: 90%;
    left: 10%;
}
.navbar ul li a.active {
  border-bottom: solid 2px #9f8468;
  font-weight: 600;
}
.innerhead {
  width: 100%;
  float: left;
}

.innerhead .header {
  position: relative;
  box-shadow: 0px 1px 6px rgb(85 85 85 / 53%);
}

.innerhead #header.navbar {
  width: 100%;
}
.footerbox ul li a {
  color: #000;
  text-decoration: none;
}

#companies .companiesbox span {
    color: #000;
}
.headerstr a {
  display: flex;
}
img {
  max-width: 100%;
}
.mobile-show{
  display: none;
}
.desktop-show{
  display: block;
}
.hemburgur span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #000;
    transition: 0.5s ease-in-out;
    float: left;
    position: absolute;
}
.hemburgur span:nth-child(1) {
  top: 0;
}

.hemburgur span:nth-child(2) {
  top: 12px;
}

.hemburgur span:nth-child(3) {
  top: 24px;
}
.isActive.menushow button.hemburgur span:nth-child(2) {
    display: none;
}

.isActive.menushow button.hemburgur span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 15px;
    left: 15px;
    width: 80%;
    height: 2px;
}

.isActive.menushow button.hemburgur span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 15px;
    left: 14px;
    width: 80%;
    height: 2px;
}
button.hemburgur {
  display: none;
  width: 40px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  position: relative;
  float: right;
  z-index: 9;
  right: 0;
  background: transparent;
  border: none;
  padding: 0;
}
.circleimg {
  width: 220px;
  height: 220px;
  margin: 0px auto;
  padding: 5px;
}
.companiesbox img {
  transition: ease all 0.5s;
}
.navbar ul li a:hover {
  color: #9f8468;
}
p {
  margin: 1em 0;
}
.accordion__button[aria-expanded="true"] {
  color: #9f8468;
}
.circleimg img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  transition: ease all 0.5s;
  transform: scale(1);
}
.companiesbox:hover img {
  transform: scale(1.1);
}
.descript {
  position: absolute;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 85%);
  padding: 30px;
  width: 100%;
  height: 100%;
  transition: ease all 0.5s;
  overflow: hidden;
  visibility: hidden;
}
.peoplebox:hover .descript {
  visibility: visible;
  display: block;
  opacity: 1;
  top: 0;
}
.descript p {
  color: #fff;
  text-align: left;
  margin: 0;
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
}
.Accordians {
  width: 70%;
  float: left;
  margin: 0 15%;
}

.accordion__item {
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 0 0;
  padding: 5px 0 0 0;
  border-top: solid 2px #9f8468;
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 1px;
}
.accordion__button[aria-expanded="false"] {
  position: relative;
  width: 100%;
  float: left;
}

.accordion__button:before {
  content: '';
  width: 22px;
  height: 2px;
  background-color: #000;
  right: 0;
  left: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  transition: ease all 0.5s;
}

.accordion__button {
  position: relative;
}

.accordion__button:after {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #000;
  right: 12px;
  left: auto;
  position: absolute;
  top: 50%;
  transform: rotate(90deg);
  transition: ease all 0.5s;
}
.accordion__button[aria-expanded="true"]:after {
  content: '';
  transform: rotate(180deg);
}
.exitestn ul {
  margin: 0;
  width: 100%;
  float: left;
}

.exitestn ul li p {
  margin: 0;
}
.link a {
  color: #000;
  text-decoration: none;
  position: relative;
}
.link:before {
  content: '';
  position: absolute;
  background: #9f8468;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: ease all 0.5s;
  width: 0;
}

.link:hover:before {
  width: 100%;
}
.copyright p a {
  text-decoration: none;
  color: #000;
}
.footerbox ul li a:hover {
  color: #9f8468;
}
div#notfound {
  width: 100%;
  float: left;
  margin: 25vh 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notfound {
  width: 50%;
  text-align: center;
}

.notfound h2 {
  margin: 0;
}

.notfound h1 {
  margin: 0;
}

div#notfound a.active {
  color: #9f8468;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}
.webbanner img {
  width: 100%;
  height: 630px;
  object-fit: cover;
  object-position: center;
}

.webbanner .icon img {
  width: 50px;
  height: auto;
}
.exiteus {
  padding: 150px 0 0 0;
}
.btn.plus {
  font-size: 30px;
  font-weight: 200;
}
.accordion .accordion-item .title {
  background: transparent;
  border: none;
}

.currentopening .accordion {
  border: none;
}

.currentopening .accordion .accordion-item .title {
  padding: 0;
  font-size: 20px;
  font-weight: 500;
}

.accordion .accordion-item .title:before {
  content: '';
  background: transparent;
}

.currentopening .accordion-item {
  margin: 0;
  border-top: 2px solid #9f8468;
  padding: 10px 0;
}
.currentopening .accordion .accordion-item .title {
  padding: 0;
  background: transparent;
}

.currentopening .accordion .accordion-item.active .title {
    background: transparent;
    color: #9f8468;
}
.accordion {
  border: none;
}
.accordion .accordion-item .panel {
  text-align: left;
}
.panel {
  overflow-y: scroll !important;
}
.currentopening .accordion .accordion-item .title:after {
  content: '\002B';
  font-size: 36px;
  float: right;
  margin-left: 5px;
  font-weight: 300;
  color: #000;
}
.currentopening .accordion .accordion-item.active .title:after {
  content: '\2212';
}
.downarrow {position: absolute;bottom: 0;left: 55%;transform: translate(-50%,-50%);}

.downarrow img.animated.bounce {
    height: auto;
    width: auto;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
.animated {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {

  0%,
  100% {
-webkit-transform: translateY(0)
  }

  50% {
-webkit-transform: translateY(-8px)
  }
}

@keyframes bounce {

  0%,
  100% {
transform: translateY(0)
  }

  50% {
transform: translateY(-8px)
  }
}
.d-none {
  display: none;
}
.contactdetails .contact50 p span a {
  width: 100%;
  float: left;
}
@media (max-width:1440px){
  .logo img {
    width: 220px;
}
.companiesbox .circleimg img{
  width: 100%;
}
.companiesbox img {
  width: 100px;
}
.header {
    padding: 10px 0;
}

.navbar ul li {
    padding: 0 12px;
}

.bannercont h2 {
    font-size: 22px;
    line-height: 26px;
}

.bannercont p {
    font-size: 19px;
    line-height: 24px;
}

.title {
    font-size: 26px;
    line-height: 30px;
}

.companiesbox span {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.5px;
}

.footerbox p {
    font-size: 13px;
    padding-right: 0;
    line-height: 24px;
}

.footerbox ul li {
    font-size: 16px;
    line-height: 50px;
}

.footerbox ul {
    margin: 0;
}

.footerbox h3 {
    margin: 10px 0;
    font-size: 25px;
    line-height: 32px;
}

.footerbox h4 {
    margin-top: 24px;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 10px;
}

.footer {
    padding-left: 0;
    padding-right: 0;
}

.footercontainer {
    width: 86%;
}

.footerbox:nth-child(2) {
    width: auto;
}

.footerbox {
    width: 34%;
}
.icon02 .circle {
  width: 300px;
  height: 300px;
}

.circle {
  width: 250px;
  height: 250px;
}
.navbar ul li a {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 4px;
}

.companiesbox {
  margin: 0 1%;
  overflow: visible;
  width: 16%;
}

.circleimg {
  width: 175px;
  height: 175px;
}
.companies.sector {
  margin-top: 25px;
}

.btn span {
  width: 50px;
  height: 50px;
}

.btn a {
  font-size: 13px;
}

.btn span:before {
  font-size: 15px;
  position: relative;
  left: 2px;
}

.companiesstn {
  margin: 25px 0;
}

.accordion .accordion-item .panel p, .content50 p, .investcont p, .exiteuscont p, .heading p, .esgcont p, .aboutcont p, .accordion__panel p, .descript p, .exiteuscont ul li  {
  font-size: 13px;
  line-height: 28px;
}
.exiteus .exitestn:nth-child(odd) .icon02 {
  left: -8%;
}
.descript {
  padding: 15px;
}
.btn.plus {
  font-size: 26px;
}
.bannerconts h3 {
  font-size: 40px;
  line-height: 50px;
}

.link {
  font-size: 16px;
}

.exiteuscont h3 {
  font-size: 20px;
}

.exitestn.investments .exiteuscont h3:before {
  width: 35%;
}
.exitestn.investments .exiteuscont {
  margin-left: 5%;
  margin-right: 10%;
}

.exiteus .exitestn:nth-child(odd) .exiteuscont {
  margin-left: 15%;
  margin-right: 5%;
}
.peoplebox {
  width: 28%;
  margin: 0 2% 50px;
}
.accordion__item {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.accordion__button:before {
  width: 16px;
  height: 1px;
}
.accordion__button:after {
  width: 15px;
  height: 1px;
  right: 9px;
}
.peoplecont h4 {
  font-size: 16px;
  line-height: 26px;
}

.peoplecont span {
  font-size: 11px;
  line-height: 20px;
}

.peoplecont {
  padding: 20px 40px;
  height: auto;
}
.copyright p {
  font-size: 11px;
  letter-spacing: 0.3px;
  line-height: 20px;
}
.esgcont h5 {
  font-size: 15px;
  margin-bottom: 0;
}

.esgcont p {
  margin-top: 5px;
}
.contactdetails .contact50 h4 {
  font-size: 17px;
  line-height: 22px;
}

.contactdetails .contact50 p {
  font-size: 12px;
  line-height: 24px;
}
.exiteuscont {
  margin-left: 5%;
  margin-right: 15%;
}
.webbanner .title {
  font-size: 22px;
  line-height: 30px;
}
.navbar.active .header .logo img {
  width: 65%;
}
.webbanner img {
  height: 500px;
}
.investcont p {
  margin-top: 30px;
}
.peopleimg {
  height: 375px;
}
.currentopening .accordion .accordion-item .title {
  font-size: 16px;
}

.currentopening .accordion-item {
  padding: 8px 0;
}

.currentopening .accordion .accordion-item .title:after {
  font-size: 28px;
}
}
@media (max-width:1390px){
  .logo img {
    width: 180px;
}
.descript p {
  height: 94%;
  overflow-x: auto;
}

.descript p::-webkit-scrollbar {
  overflow: visible;
  width: 6px;
  padding: 0;
  background: #f1f1f1;
}

.descript p::-webkit-scrollbar-thumb {
  background-color: #9f8468;
}
.exiteus {
  padding-top: 100px;
}
.btn.plus {
  font-size: 22px;
}
.webbanner img {
  height: 400px;
}
.link {
  font-size: 14px;
  line-height: 20px;
  height: auto;
}
.navbar ul li a {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 3px;
}

.bannercont h2 {
    font-size: 18px;
    line-height: 24px;
}

.bannercont p {
  font-size: 18px;
  line-height: 24px;
}

.title {
    font-size: 20px;
    line-height: 26px;
}
.companiesbox span {
  font-size: 12px;
  line-height: 18px;
}

.btn a {
  font-size: 11px;
}

.btn span {
  width: 40px;
  height: 40px;
}

.footerbox p {
  font-size: 12px;
  line-height: 22px;
}

.footerbox ul li {
  font-size: 14px;
}

.footerbox h3 {
  font-size: 21px;
  line-height: 30px;
}

.footerbox h4 {
  font-size: 14px;
  line-height: 22px;
}
.icon02 .circle {
  width: 220px;
  height: 220px;
  border-width: 3px;
}

.whoweare .border1 {
  width: 20px;
  height: 20px;
  border-width: 2px;
  top: 23%;
}

.circle {
  width: 210px;
  height: 210px;
  border-width: 3px;
}

.icon02 {
  width: 180px;
  height: 180px;
}

.investment .border3 {
  left: 10%;
  top: 28%;
}

.investment .icon02 {
  top: 15%;
  left: -3%;
}
.investcont p {
  margin-top: 25px;
}
.exitestn.investments .exiteuscont h3:before {
  width: 30%;
}
}
@media (max-width:1200px){
  .navbar ul li {
    padding: 0 8px;
}

.navbar ul li a {
    font-size: 12px;
}

.logo img {
    width: 180px;
}

.aboutcont {
  width: 40%;
  margin-left: 5%;
  margin-right: 5%;
}

.companiesbox {
    width: 17%;
}

.content50 p {
    width: 48%;
}

.bannerconts h3 {
    font-size: 36px;
    line-height: 50px;
}

.title {
    font-size: 18px;
    line-height: 20px;
}

.investment {
    margin-bottom: 0;
}

.exiteuscont {
    width: 40%;
}
.footercontainer {
    width: 92%;
}

.footer {
    padding: 25px;
}

.footerbox h3 {
    margin-top: 0;
}

.footerbox h4 {
    margin-top: 0;
}

.footerbox p {
    font-size: 11.5px;
    line-height: 21px;
}

.footerbox ul li {
    font-size: 13px;
    line-height: 40px;
}

.content50 p, .investcont p, .exiteuscont p, .heading p, .esgcont p, .aboutcont p, .accordion__panel p, .exiteuscont ul li {
    font-size: 12px;
    line-height: 22px;
}
.companiesbox {
  width: 19%;
}
.link {
  font-size: 14px;
  line-height: 20px;
  height: auto;
}

.footerbox h3 {
  font-size: 18px;
  line-height: 26px;
}

.footerbox h4 {
  font-size: 12px;
  line-height: 16px;
}

.bannerconts h3 {
  font-size: 30px;
  line-height: 40px;
}

.content50 {
  width: 90%;
  margin: 0 5%;
}

.exiteuscont h3 {
  font-size: 17px;
  line-height: 26px;
}
footer {
  padding-top: 50px;
}
}
@media (max-width:940px){
  .webbanner .title {
    top: 25%;
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.5px;
}

.bannerconts h3 {
    font-size: 24px;
    line-height: 36px;
}

.contactdetails .contact50 h4 {
    font-size: 16px;
    line-height: 20px;
}

.contactdetails {
    width: 100%;
    margin: 0;
}
}
@media (max-width:768px){
.mobile-show{
  display: block;
}
.desktop-show, #about {
    display: none;
}
button.hemburgur{
  display: block;
}
ul.display-flex {
  position: fixed;
  top: 0;
  background: rgb(65 64 66 / 90%);
  left: 0;
  padding: 25px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: opacity .35s,visibility .35s,height .35s;
  height: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding-top: 100px;
}

.bannercont h2 {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 26px;
}

.bannercont p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 80%;
}

.companiesbox {
  width: 30%;
  margin: 10px 5%;
}

.footerbox, .footerbox:nth-child(2) {
  width: 100%;
  margin-bottom: 20px;
}

.footercontainer {
  flex-wrap: wrap;
}

.footerbox ul {
  padding: 0;
}

.footerbox p {
  font-size: 12px;
  line-height: 24px;
}
.isActive.menushow ul.display-flex {
  display: block;
  height: 100vh;
}
.content50 p {
  margin: 0;
  width: 100%;
}

.content50 {
  flex-wrap: wrap;
}
.investment .icon02 {
  top: 40%;
}

.investment .border3 {
  left: 15%;
  top: 48%;
}

.link {font-size: 13px;}

.investcont {
  width: 70%;
  margin: 0;
}

.investment {
  flex-wrap: wrap;
}

.investimg {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-left: 10%;
}

.exiteus .exitestn:nth-child(odd) .exiteuscont, .exiteuscont, .exitestn.investments .exiteuscont {
  width: 80%;
  margin: 50px 10%;
}

.exitestn {
  width: 100%;
  flex-wrap: wrap;
}
.peoplebox {
  width: 100%;
}
.web-container {
  width: 90%;
}
.esfbox {
  width: 44%;
}

.esfstn {
  flex-wrap: wrap;
}
.aboutus {
  flex-wrap: wrap;
}

.aboutimg {
  width: 90%;
}

.aboutcont {
  width: 80%;
  margin-top: 30px;
  margin-left: 10%;
}

.companiesstn {
  margin: 0;
}

.companies.sector {
  margin: 0;
  padding: 25px 0;
  background-repeat: no-repeat;
}

.companiesbox span {
  margin-top: 5px;
}

.circle {
  width: 150px;
  height: 150px;
}

.icon02 .circle {
  width: 140px;
  height: 140px;
}

.icon .borderfil4 {
  bottom: 0;
}

.icon02.icon05 {top: -50px;}
.bannercont p {
  width: 100%;
}
.footercontainer {
  width: 100%;
}
.bannercont h2{
  margin-top: 0;
}
.navbar.active .header .logo img, .logo img {width: 200px;}
.navbar ul li {
  padding: 0;
  text-align: center;
}

.navbar ul li a {
  font-size: 28px;
  line-height: 60px;
  color: #fff;
  font-family: 'Playfair Display', serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  display: inline-block;
}
.navbar ul li:last-child {
  border: none;
}
.icon.icon02 {
  display: none;
}
.header {
  width: 100%;
  background: #fff;
  position: static;
}
.navbar {
width: 100%; 
}
.banner img {
height: auto;
}
.icon01 {
  bottom: -100px;
  right:0px;
}
.circle.right-align, .aboutcont .border2 {
  right:0%;
}
.bannercont {
  position: static;
  width: 60%;
  margin: 30px 20% 0;
}
footer {
  width: 94%;
  margin: 0px 3%;
}
footer .container {
  width: 100%;
}
.aboutstn {
  padding: 50px 0;
}
.isActive.menushow span {
    background: #fff;
}
.exiteus {
  overflow: hidden;
}
.exitestn.investments .exiteuscont h3:before {
  width: auto;
}
.esg .border1.sec {
  right: 0;
}

.esg .border3 {
  right: 0;
}

.esg .border2 {right: 0;}
}
@media (max-width:680px){

.headerstr {
  align-items: center;
}
.heading {
  margin-bottom: 25px;
}
.webbanner img {
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.exitestn .border1, .exiteuscont .borderfil4 {
  right: 0;
}
.webbanner .title {
  top: 20%;
  font-size: 16px;
  width: auto;
  left: 50%;
  white-space: nowrap;
}
.investimg {
  margin-bottom: 50px;
}
.link {
  padding: 0;
  width: 100%;
  line-height: 40px;
}
.navbar.active .header {
  z-index: 99;
}
.bannercont {
  width: 70%;
  margin-left: 15%;
}
.bannerconts, .companies.aboutbanner2 {
  background-attachment: inherit;
}
.bannercont h2 {
  margin-bottom: 0;
}
.webbanner .icon img {
  height: 70px;
}
}
@media (max-width:480px){
  .banner .icon img{
    width: 30px;
  }
  .title {
    width: 100%;
    text-align: center;
}
.Accordians {
  width: 100%;
  margin: 0;
}
.companies, .whoweare, .bannerconts, .investment, .exiteus, .esg, .contactdetils, .currentopening {
  padding: 25px 0;
}
footer {
  padding-top: 25px;
  width: 100%;
  margin: 0;
}
.accordion__item {
  font-size: 13px;
}
.title:before {left: auto;transform: translateX(-120%);height: 2px;width: 50px;}

.companiesbox {
  width: 100%;
  margin: 10px 0;
}
.companiesbox span{
  margin: 0;
}
.companies.sector .companiesbox{
  width: 50%;
  margin: 10px 0;
}
.companies.sector .companiesbox img {
    width: 100px;
}
.companies.sector {
    height: auto;
    background-size: cover;
}

.web-container {
  width: 100%;
}
.webbanner .icon img {position: relative;}

.webbanner {
  position: relative;
}


.circle.right-align {
  width: 60px;
  height: 60px;
  border-width: 1px;
}

.bannerconts h3 {
  width: 90%;
}

.content50 p strong {
  margin-top: 10px;
}
.heading p {
  width: 100%;
  margin: 20px 0 0 0;
}

.esfbox {
  width: 100%;
  margin-bottom: 20px;
}
.circle {
  width: 100px;
  height: 100px;
}

.currentopening .accordion .accordion-item .title {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.icon02 .circle {
  width: 70px;
  height: 70px;
}
.esgcont p {
  margin-bottom: 0;
}
}
@media (max-width:420px){
  .careerlink a {
    font-size: 13px;
    line-height: 20px;
}

.currentopening .accordion .accordion-item .title {
    font-size: 12px;
    letter-spacing: 0.5px;
}

.currentopening .accordion .accordion-item .title:after {
    font-size: 24px;
}

.currentopening .accordion-item {
    border-top: 1px solid;
}
  .border1 {
    border: solid 3px #9f8468;
}
.copyright p {width: 100%;text-align: center;margin: 0;}

.copyright {
    flex-wrap: wrap;
    padding: 10px 0;
}

.icon01 {
  bottom: 100px;
}
.peoplebox {
  width: 100%;
}

.peoplebox img {
  width: 100%;
}

.border2 {
    border-width: 2px;
}
.contactdetails .contact50:nth-child(1), .contact50 {
  width: 100%;
}

.contactdetails {
  flex-wrap: wrap;
}
}